// src/ReservationsContext.js
import React, { createContext, useState, useContext } from 'react';
import { endOfWeek, startOfWeek } from "date-fns";

// Crée le contexte
const ReservationsContext = createContext();


// Fournit le contexte à ses enfants
export const CalendrierProvider = ({ children }) => {
  // État initial avec un tableau pour chaque jour de la semaine
  const [reservations, setReservations] = useState({
    Lundi: [],
    Mardi: [],
    Mercredi: [],
    Jeudi: [],
    Vendredi: [],
    Samedi: [],
    Dimanche: []
  });

  const [week, setWeek] = useState(    {
    firstDay: startOfWeek(new Date(), { weekStartsOn: 1 }),
    lastDay: endOfWeek(new Date(), { weekStartsOn: 1 })
  })


  const isDateInWeek = (date)  => {
    const { firstDay, lastDay } = week;

    // Comparaison simple des dates
    return date >= firstDay && date <= lastDay;
  }

  // Ajouter une réservation
  const addReservation = (day, reservation) => {
    setReservations((prev) => ({
      ...prev,
      [day]: [...prev[day], reservation]
    }));
  };

  // Supprimer une réservation
  const removeReservation = (day, id) => {
    setReservations((prev) => ({
      ...prev,
      [day]: prev[day].filter((res) => res._id !== id)
    }));
  };

  // Mettre à jour toutes les réservations
  const updateAllReservations = (newReservations) => {
    setReservations(newReservations);
  };

  // Mettre à jour toutes les réservations
  const updateWeek = (newWeek) => {
    setWeek(newWeek);
  };

  const editReservationFormaComment = (day, id, updatedReservation) => {
     setReservations((prev) => ({
        ...prev,
        [day]: prev[day].map((res) => (res._id === id ? updatedReservation : res))
      }));
  }

  // Éditer une réservation
  const editReservation = (oldDay, newDay, oldDate, newDate, id, updatedReservation) => {

    // Si meme date on update
    if(new Date(oldDate).getMonth() === new Date(newDate).getMonth() && new Date(oldDate).getDay() === new Date(newDate).getDay() && new Date(oldDate).getFullYear() === new Date(newDate).getFullYear()) {
      setReservations((prev) => ({
        ...prev,
        [oldDay]: prev[oldDay].map((res) => (res._id === id ? updatedReservation : res))
      }));
    } else {
      // Si date différente, on check si on est dans la meme semaine
      if(isDateInWeek(newDate)) {
        // Si oui on enleve l'ancienne résa et on mets la nouvelle dans un jour différent
        setReservations((prev) => {
          // Supprimer la réservation de l'ancien jour
          let updatedOldDayReservations = prev[oldDay].filter((res) => res._id !== id);

          // Ajouter ou mettre à jour la réservation pour le nouveau jour
          let updatedNewDayReservations;
          if (oldDay === newDay) {
            // Si le jour n'a pas changé, mettre à jour simplement la réservation
            updatedNewDayReservations = updatedOldDayReservations.map((res) =>
              res._id === id ? updatedReservation : res
            );
          } else {
            // Sinon, ajouter la réservation mise à jour au nouveau jour
            updatedNewDayReservations = [...prev[newDay], updatedReservation];
          }

          return {
            ...prev,
            [oldDay]: updatedOldDayReservations,
            [newDay]: updatedNewDayReservations,
          };
        });

      } else {
        // Si non, on remove la old et c'est tout
        setReservations((prev) => {
          // Supprimer la réservation de l'ancien jour
          let updatedOldDayReservations = prev[oldDay].filter((res) => res._id !== id);

          return {
            ...prev,
            [oldDay]: updatedOldDayReservations,
          };
        });
      }
    }
  };

  return (
    <ReservationsContext.Provider
      value={{
        reservations,
        addReservation,
        removeReservation,
        updateAllReservations,
        editReservation,
        editReservationFormaComment,
        week,
        updateWeek
      }}
    >
      {children}
    </ReservationsContext.Provider>
  );
};

// Hook personnalisé pour utiliser le contexte des réservations
export const useReservations = () => useContext(ReservationsContext);
