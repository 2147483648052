import React from "react"

import { StoreProvider } from "./StoreContext"
import { CalendrierProvider } from "./CalendrierContext";

const CombinedProvider = ({ element }) => {
  return (
    <CalendrierProvider>
      <StoreProvider>{element}</StoreProvider>
    </CalendrierProvider>
  )
}

export default CombinedProvider
