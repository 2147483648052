import React, { createContext, useState, useEffect, useContext } from "react"
import fetch from "isomorphic-fetch"
import Client from "shopify-buy"

const isBrowser = typeof window !== `undefined`
const localStorageKey = `shopify_checkout_id`

const client = Client.buildClient(
  {
    domain: process.env.GATSBY_SHOPIFY_STORE_URL,
    storefrontAccessToken: process.env.GATSBY_STOREFRONT_ACCESS_TOKEN,
  },
  fetch
)

let defaultValues

if(isBrowser) {
  defaultValues = {
    cart: [],
    loading: false,
    addVariantToCart: () => { },
    removeLineItem: () => { },
    client,
    checkout: {
      id: "",
      lineItems: [],
      webUrl: ""
    },
  }
} else {
  defaultValues = {
    cart:  [],
    loading: false,
    addVariantToCart: () => { },
    removeLineItem: () => { },
    client,
    checkout: {
      id: "",
      lineItems: [],
      webUrl: ""
    },
  }
}

const StoreContext = createContext(defaultValues)

export const StoreProvider = ({ children }) => {

  const [cart, setCart] = useState(defaultValues.cart)
  const [checkout, setCheckout] = useState(defaultValues.checkout)
  const [loading, setLoading] = useState(false)


  const setCheckoutItem = (checkout) => {
    if (isBrowser) {
      localStorage.setItem(localStorageKey, checkout.id)
    }
    setCheckout(checkout)
  }

  useEffect(() => {
    const initializeCheckout = async () => {
      const existingCheckoutID = isBrowser
        ? localStorage.getItem(localStorageKey)
        : null

      if (existingCheckoutID && existingCheckoutID !== `null`) {
        try {
          let existingCheckout = await client.checkout.fetch(
            existingCheckoutID
          )

          if (!existingCheckout.completedAt) {
            setCheckoutItem(existingCheckout)
            initializeCart()
            return
          } else {
            // todo : reset cart and localstorage (cart + checkoutLineItems) here if the checkout got completed
          }
        } catch (e) {
          localStorage.setItem(localStorageKey, null)
        }
      }

      const newCheckout = await client.checkout.create()
      setCheckoutItem(newCheckout)
      setCart([])
      window.localStorage.setItem("cart", JSON.stringify([]));

    }

    const initializeCart = () => {
      let cart = [];
      if(localStorage.getItem('cart')){
        cart = JSON.parse(localStorage.getItem('cart'));
      }
      setCart(cart)
    }

    initializeCheckout()
  }, [])


  const addVariantToCart = async (product, quantity, variantIndex, comment) => {
    setLoading(true)
    if (checkout.id === "") {
      console.error("No checkout ID assigned.")
      return
    }

    const checkoutID = checkout.id
    const variantId = product.variants[variantIndex]?.shopifyId
    const parsedQuantity = parseInt(quantity, 10)


    let firstSlash =  product.description.indexOf("/");
    let secondSlash = product.description.indexOf('/', firstSlash+1)
    let date = product.description.substring(0, secondSlash);

    let customAttributes = [{key : 'Date', value: date}]

    if(comment && comment.length > 0) {
      customAttributes = [
        {key : 'Date', value: date},
        {key : 'Commentaire', value: comment}
      ]
    }

    const lineItemsToUpdate = [
      {
        variantId,
        quantity: parsedQuantity,
        customAttributes
      },
    ]
    try {
      const res = await client.checkout.addLineItems(checkoutID, lineItemsToUpdate)
      setCheckout(res)
      // window.localStorage.setItem("checkoutLineItems", JSON.stringify(res.lineItems));

      let updatedCart = []
      if (cart.length > 0) {
        const itemIsInCart = cart.find((item) => item.product.variants[0]?.shopifyId === variantId)

        if (itemIsInCart) {
          const newProduct = {
            product: { ...itemIsInCart.product },
            quantity: (itemIsInCart.quantity + parsedQuantity)
          }
          const otherItems = cart.filter((item) => item.product.variants[0]?.shopifyId !== variantId)
          updatedCart = [...otherItems, newProduct]
        } else {
          updatedCart = cart.concat([{ product, quantity: parsedQuantity }])
        }
      } else {
        updatedCart = [{ product, quantity: parsedQuantity }]
      }
      setCart(updatedCart)
      // console.log("updated cart :", updatedCart)
      window.localStorage.setItem("cart", JSON.stringify(updatedCart));

      setLoading(false)
    } catch (error) {
      setLoading(false)
      console.error(`Error in addVariantToCart: ${error}`)
    }
  }

  const removeLineItem = async (variantId) => {
    setLoading(true)

    // Getting the ID only of the variantId : (gid//shopify/variantID/ID?....)
    let variantIdSpliced = variantId.split('/');
    let variantIdFormatted = variantIdSpliced[variantIdSpliced.length-1];

    try {
      if (checkout.lineItems.length < 1) {
        throw new Error("Checkout is empty")
      }
      let lineItemID = ''
      checkout.lineItems?.forEach((item) => {
        // Getting the ID only of the lineItems : (gid//shopify/lineItems/ID?....)
        let itemIdSpliced = item.id.split('/');
        let itemIdFormatted = itemIdSpliced[itemIdSpliced.length-1];

        if (itemIdFormatted.substring(0,14) === variantIdFormatted) {
          lineItemID = item.id
        }
      })

      if (!lineItemID) {
        console.error('Product not in cart :', lineItemID)
        await resetCartAndCheckout()
        return
      }

      const res = await client.checkout.removeLineItems(checkout.id, [lineItemID])
      setCheckout(res)


      const updatedCart = cart.filter((item) => item.product.variants[0]?.shopifyId !== variantId)
      // console.log("updated Cart :", updatedCart)
      setCart(updatedCart)
      window.localStorage.setItem("cart", JSON.stringify(updatedCart));

      setLoading(false)

    } catch (error) {
      await resetCartAndCheckout()
      setLoading(false)
      console.error(`Error in removeLineItem: ${error}`)
    }
  }

  const resetCartAndCheckout = async () => {
    console.debug("resetting cart and checkout")
    const newCheckout = await client.checkout.create()
    setCheckoutItem(newCheckout)
    setCart([])
    window.localStorage.setItem("cart", JSON.stringify([]));
  }

  return (
    <StoreContext.Provider
      value={{
        ...defaultValues,
        addVariantToCart,
        removeLineItem,
        resetCartAndCheckout,
        cart,
        checkout,
        loading,
      }}
    >
      {children}
    </StoreContext.Provider>
  )
}

const useStore = () => {
  const context = useContext(StoreContext)

  if (context === undefined) {
    throw new Error("useStore must be used within StoreContext")
  }

  return context
}

export default useStore
