exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-apero-ceramique-index-js": () => import("./../../../src/pages/apero-ceramique/index.js" /* webpackChunkName: "component---src-pages-apero-ceramique-index-js" */),
  "component---src-pages-ateliers-enfants-index-js": () => import("./../../../src/pages/ateliers-enfants/index.js" /* webpackChunkName: "component---src-pages-ateliers-enfants-index-js" */),
  "component---src-pages-ateliers-index-js": () => import("./../../../src/pages/ateliers/index.js" /* webpackChunkName: "component---src-pages-ateliers-index-js" */),
  "component---src-pages-ateliers-merci-js": () => import("./../../../src/pages/ateliers/merci.js" /* webpackChunkName: "component---src-pages-ateliers-merci-js" */),
  "component---src-pages-ateliers-modelage-index-js": () => import("./../../../src/pages/ateliers-modelage/index.js" /* webpackChunkName: "component---src-pages-ateliers-modelage-index-js" */),
  "component---src-pages-autre-ateliers-index-js": () => import("./../../../src/pages/autre-ateliers/index.js" /* webpackChunkName: "component---src-pages-autre-ateliers-index-js" */),
  "component---src-pages-cafe-ceramique-merci-js": () => import("./../../../src/pages/cafe-ceramique/merci.js" /* webpackChunkName: "component---src-pages-cafe-ceramique-merci-js" */),
  "component---src-pages-calendrier-index-js": () => import("./../../../src/pages/calendrier/index.js" /* webpackChunkName: "component---src-pages-calendrier-index-js" */),
  "component---src-pages-cart-js": () => import("./../../../src/pages/cart.js" /* webpackChunkName: "component---src-pages-cart-js" */),
  "component---src-pages-contact-index-js": () => import("./../../../src/pages/contact/index.js" /* webpackChunkName: "component---src-pages-contact-index-js" */),
  "component---src-pages-contact-merci-js": () => import("./../../../src/pages/contact/merci.js" /* webpackChunkName: "component---src-pages-contact-merci-js" */),
  "component---src-pages-products-js": () => import("./../../../src/pages/products.js" /* webpackChunkName: "component---src-pages-products-js" */),
  "component---src-templates-about-page-js": () => import("./../../../src/templates/about-page.js" /* webpackChunkName: "component---src-templates-about-page-js" */),
  "component---src-templates-boutique-page-js": () => import("./../../../src/templates/boutique-page.js" /* webpackChunkName: "component---src-templates-boutique-page-js" */),
  "component---src-templates-cafe-ceramique-js": () => import("./../../../src/templates/cafe-ceramique.js" /* webpackChunkName: "component---src-templates-cafe-ceramique-js" */),
  "component---src-templates-condition-de-vente-page-js": () => import("./../../../src/templates/condition-de-vente-page.js" /* webpackChunkName: "component---src-templates-condition-de-vente-page-js" */),
  "component---src-templates-confidentialite-page-js": () => import("./../../../src/templates/confidentialite-page.js" /* webpackChunkName: "component---src-templates-confidentialite-page-js" */),
  "component---src-templates-index-page-js": () => import("./../../../src/templates/index-page.js" /* webpackChunkName: "component---src-templates-index-page-js" */),
  "component---src-templates-le-lieu-page-js": () => import("./../../../src/templates/le-lieu-page.js" /* webpackChunkName: "component---src-templates-le-lieu-page-js" */),
  "component---src-templates-mention-legale-page-js": () => import("./../../../src/templates/mention-legale-page.js" /* webpackChunkName: "component---src-templates-mention-legale-page-js" */),
  "component---src-templates-product-js": () => import("./../../../src/templates/product.js" /* webpackChunkName: "component---src-templates-product-js" */),
  "component---src-templates-vos-evenements-js": () => import("./../../../src/templates/vos-evenements.js" /* webpackChunkName: "component---src-templates-vos-evenements-js" */)
}

